import React, { useState } from "react"
import { navigate } from "gatsby"
import "./lifestyle.scss"

const Lifestyle = ({ classNames, pageHref, onSuccess }) => {
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async e => {
    if (isLoading) return
    e.preventDefault()

    const formData = new FormData()

    formData.append("firstName", firstName)
    formData.append("lastName", lastName)
    formData.append("phoneNumber", phoneNumber)
    formData.append("from", pageHref)

    setIsLoading(true)
    fetch("https://getform.io/f/byvpmqya", {
      method: "POST",
      body: formData,
      headers: {
        Accept: "application/json"
      }
    })
      .then(response => {
        navigate("/thank-you/")
        setIsLoading(false)
      })
      .catch(error => {
        console.error(error)
        setIsLoading(false)
      })
  }

  const handleChange = e => {
    switch (e.target.name) {
      case "firstName":
        setFirstName(e.target.value)
        break
      case "lastName":
        setLastName(e.target.value)
        break
      case "phoneNumber":
        setPhoneNumber(e.target.value)
        break
      default:
        break
    }
  }

  return (
    <form
      className={`form lifestyle-contact-form ${classNames}`}
      id="lifestyle-contact-form"
      onChange={handleChange}
      onSubmit={handleSubmit}>
      <div className="form-group">
        <label htmlFor="firstName">First Name</label>
        <input type="text" name="firstName" required />
      </div>
      <div className="form-group">
        <label htmlFor="lastName">Last Name</label>
        <input type="text" name="lastName" required />
      </div>
      <div className="form-group">
        <label htmlFor="phoneNumber">Phone Number</label>
        <input type="tel" maxLength={16} name="phoneNumber" required />
      </div>
      <input
        type="hidden"
        name="_gotcha"
        style={{ display: "none !important" }}></input>
      <button disabled={isLoading} type="submit">
        {isLoading ? "Submitting..." : "Submit"}
      </button>
    </form>
  )
}

export default Lifestyle
