import { graphql } from "gatsby"
import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import TheaterVideo from "../components/Theater/TheaterVideo"
import { Image, Text } from "../components/Core"
import Lifestyle from "../components/forms/Lifestyle"
import { Phone } from "../components/locations"
import TestimonialGrid from "../components/Cards/TestimonialGrid"

const LifestylePatientPage = ({ data, location }) => {
  const {
    metaTitle,
    metaDescription,
    isRobotsIndex,
    hero,
    about,
    form,
    quote,
    phone
  } = data.allLifestylePatientsJson.nodes[0]

  let allReviews = data.allReviews.nodes
  return (
    <Layout noFooter noMenu>
      <SEO
        title={metaTitle}
        description={metaDescription}
        pathname={location.pathname}
        robots={!isRobotsIndex ? "noindex" : ""}
      />
      <div id="mainNav" className="lifestyle ls__patient ">
        <div className="ls__section small">
          <div className="columns">
            <div className="column is-3"></div>
            <div className="column">
              <h1
                className="has-text-centered mb-0"
                dangerouslySetInnerHTML={{ __html: hero.heading }}
              />
              <div className="column mt-1 ls__call-cta">
                <Text as="h2" text={"Call for More Information"} />
                <Phone
                  language={"en"}
                  phoneNumber={phone}
                  className="standard-button contained"
                />
              </div>
            </div>
            <div className="column is-3"></div>
          </div>
        </div>

        {/* <div className="ls__section small">
          <div className="columns">
            <div className="column is-3"></div>
            <div className="column ls__call-cta">
              <Text as="h2" text={"Call for More Information"} />
              <Phone
                language={"en"}
                phoneNumber={phone}
                className="standard-button contained"
              />
            </div>
            <div className="column is-3"></div>
          </div>
        </div> */}

        <div className="ls__section">
          <div className="columns is-vcentered">
            <div className="column is-3"></div>
            <div className="column">
              <Image useAR cloudName="nuvolum" publicId={quote.image} />
            </div>
            <div className="column is-1"></div>
            <div className="column">
              <p className="px-40--mobile mt-2--mobile ls__patient-quote">
                <i className="bold">{`"${quote.text}"`}</i>
              </p>
              <TheaterVideo
                videoUrl={`https://www.youtube.com/watch?v=${hero.youtube.code}`}
                controls
                playing
                buttonClass="px-40--mobile ls__theater-btn contained"
              />
            </div>
            <div className="column is-3"></div>
          </div>
        </div>

        <TestimonialGrid language={"en"} reviews={allReviews} />

        <div className="ls__section">
          <div className="columns is-desktop">
            <div className="column is-3"></div>
            <div className="column">
              <h2>{about.heading}</h2>
              <Text className="mt-1" text={about.blurb} />
            </div>
            <div className="column is-1"></div>
            <div className="column">
              <div className="ls__form-sidebar">
                <Text className="ls__form-header" text={form.content} />
                <Lifestyle
                  pageHref={location.href}
                  onSuccess={() => navigate("/thank-you/")}
                />
              </div>
            </div>
            <div className="column is-3"></div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const lifestylePatientPageQuery = graphql`
  query lifestylePatientPage($title: String!) {
    allLifestylePatientsJson(filter: { title: { eq: $title } }) {
      nodes {
        metaTitle
        metaDescription
        isRobotsIndex
        hero {
          heading
          youtube {
            code
            image
          }
        }
        about {
          heading
          blurb
        }
        form {
          content
          url
        }
        quote {
          text
          image
        }
        phone
      }
    }
    allReviews: allReviewsJson(
      filter: {
        mainProcedureDone: { eq: "Dental Implants" }
        reviewType: { eq: "Patient" }
        language: { eq: "en" }
      }
      limit: 30
    ) {
      nodes {
        ...FacewallData
      }
    }
  }
`

export default LifestylePatientPage
